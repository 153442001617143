/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import "./src/styles/form.css"
// import "./src/styles/mobilenav.css"
import "./src/styles/dropdown.css"
import './node_modules/materialize-css/dist/js/materialize.js'
import './node_modules/materialize-css/dist/css/materialize.css'
import 'typeface-montserrat'
import 'typeface-fira-sans-condensed'

import React from "react";
import Firebase, {FirebaseContext} from './src/firebase'
import AuthProvider from './src/auth/authProvider';

export const wrapRootElement = ({element}) => {
  return(
  <FirebaseContext.Provider value={new Firebase()}>
    <AuthProvider>
      {element}
    </AuthProvider>
  </FirebaseContext.Provider>
  )
}

const MessengerChat = require("./src/components/messenger").default;

export const wrapPageElement = ({ element, props }) => (
  <React.Fragment >
    {element}
    <MessengerChat />
  </React.Fragment>
);