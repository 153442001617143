import React, {useEffect} from 'react'

const MessengerChat = () => {

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml            : true,
        version          : 'v9.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },)

  return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="211298202217606"
        theme_color="#5a4fec"
        logged_in_greeting="Hi there! How can we help you?"
        logged_out_greeting="Hi there! How can we help you?" />
    </>
  )
}

export default MessengerChat